import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HandsCapture from './views/hands-capture/index';
import Login from './views/login'
import './App.css';
import SignIn from './views/SignIn';
import {validateToken} from "./components/api/api";
import PrivateRoute from "./components/PrivateRoute";


function App() {
    const [isAuth, setIsAuth] = useState(false)

    useEffect(  () => {
        async function refreshToken() {
            const auth = localStorage.getItem('pocketbase_auth');

            if (!auth){
                setIsAuth(false)
                return
            }

            const authObject = JSON.parse(auth);
            await validateToken(authObject.token)
                .then(data => data).then(res => {
                    if (res.token){
                        setIsAuth(true)
                        localStorage.setItem('pocketbase_auth', JSON.stringify(res))
                    }
                })
                .catch(error => {
                    console.log(error)
                    setIsAuth(false)
                });
        }
        refreshToken()})


    return (
        <Router>
            <Routes>
                <Route element={<PrivateRoute/>}>
                    <Route
                        path="/"
                        element={<HandsCapture/>}/>
                </Route>
                <Route
                    path="/login"
                    element={<Login setIsAuth={setIsAuth}/>}/>
                <Route
                    path="/signin"
                    element={<SignIn/>}/>
            </Routes>
    </Router>
    );
}

export default App;
